import React from 'react';
import './Home.css'; // You need to create this CSS file
import lexicaImg from '../assets/images/lexicaold.png'; 
import trollImg from '../assets/images/troll.png'; 
import cardsImg from '../assets/images/cards.png'; 
import dragonImg from '../assets/images/dragon.png'; 
import worldsImg from '../assets/images/worlds.png'; 
import { Button, Row, Col } from 'react-bootstrap';


const HomePage = () => {
    return (
      <div style={{ backgroundColor: 'black', height: '100%' }}>
            <Row/>
            <Row className="justify-content-center"><Col xl={10}>
        <div className="lexica-page text-center">
            <header className="lexica-header">
                <h1>Lexica: Embark on a Vocabulary Adventure</h1>
                
            </header>

            <main>
                <section className="lexica-intro">                   
                    <p>
                    Welcome to Lexica, an innovative RPG game designed to make learning new words an exciting and immersive experience. In Lexica, players enter a captivating world filled with mystery, exploration, and the power of words. Whether you're a budding wordsmith or simply eager to expand your vocabulary, Lexica offers an immersive journey that combines education and entertainment.
                    </p>
                    <img src={lexicaImg} class="img-fluid img-max-width my-3" alt="Welcome to Lexica" />
                    <Button href="https://game.playlexica.com" >Play Lexica</Button>
                </section>

                <section className="lexica-content">                  
                    <p>
                    In the vast realms of Lexica, players encounter a variety of Artificial Intelligence (AI) characters, each with their own unique personalities and areas of expertise. These AI companions act as friendly guides, providing safe and engaging conversations where players can interact and learn. Through conversations with these AI characters, players embark on quests, solve riddles, and uncover the meanings of unfamiliar words.
                    </p>
                    <img src={trollImg} class="img-fluid img-max-width my-3" alt="Play Lexica" />
                </section>

                <section className="lexica-content">
                  <p>
                    As players progress, they unlock the power of words and collect virtual cards representing each new word they discover. These captivating cards depict vivid and memorable images related to the word, aiding players in retaining and recalling their newfound vocabulary. The enchanting artwork and imaginative designs bring the words to life, transforming the learning process into an adventure of visual delight.
                  </p>
                  <img src={cardsImg} class="img-fluid img-max-width my-3" alt="Lexica Image" />
                </section>

                <section className="lexica-content">
                  <p>
                    Lexica is not only about language exploration and AI interaction but also incorporates classic RPG elements. Players traverse diverse and enchanting worlds, including lush forests, treacherous dungeons, snowy landscapes, scorching deserts, and fiery lava realms. Along their journey, players encounter formidable dragons, and to overcome these challenges, they must strategically employ the words and spells they have learned throughout the game.
                  </p>
                  <img src={dragonImg} class="img-fluid img-max-width my-3" alt="Lexica Image" />
                </section>

                <section className="lexica-content">
                  <p>
                    While exploring the vibrant RPG world, players have the opportunity to collect coins, uncover hidden treasures, and unlock new areas. These rewards incentivize engagement, motivating players to dive deeper into Lexica's immersive environment and continue expanding their vocabulary.
                  </p>
                  <img src={worldsImg} class="img-fluid img-max-width my-3" alt="Lexica Image" />
                </section>

                <section className="lexica-content">
                  <p>
                  Lexica promotes a safe and enriching gameplay experience, where players can sharpen their language skills, exercise critical thinking, and immerse themselves in a world brimming with educational content. By combining the power of AI companions, captivating virtual cards, and thrilling RPG gameplay, Lexica creates an interactive and engaging learning journey for players of all ages.
                  </p>
                  <p>
                  Note: Lexica is designed with the utmost priority given to the safety and security of its players. All AI characters and interactions within the game are carefully curated and monitored to ensure a completely safe and child-friendly environment.
                  </p>
                </section>
            </main>

            <footer className="lexica-footer">
                <p>&copy; 2023 PlayLexica.com - All Rights Reserved</p>                
            </footer>
        </div>
        </Col></Row>
        </div>
    );
};

export default HomePage;
