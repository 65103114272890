import React from 'react';
import { useRoutes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import JoinGameRoom from '../pages/game/JoinGameRoom';

import GameRooms from '../pages/dashboard/GameRooms';
import Chat from '../pages/game/Chat';

import Login from '../pages/account/Login';
import Logout from '../pages/account/Logout';
import Register from '../pages/account/Register';
import ForgetPassword from '../pages/account/ForgetPassword';
import NewGameRoom from '../pages/dashboard/NewGameRoom';
import GameRoom from '../pages/dashboard/GameRoom';
import Challenge from '../pages/game/Challenge';
import Pricing from '../pages/account/Pricing';
import PaymentSuccess from '../pages/account/PaymentSuccess';
import PaymentFailure from '../pages/account/PaymentFailure';
import Subscriptions from '../pages/dashboard/Subscriptions';
import Students from '../pages/dashboard/Students';
import HomePage from '../pages/Home';

const AllRoutes = () => {
 
  let Layout = VerticalLayout;

  return useRoutes([
    { path: '/', element: <HomePage /> },
    {
      // public routes
      path: '/',
      element: <DefaultLayout />,
      children: [
        {
          path: 'account',
          children: [
            { path: 'login', element: <Login/> },
            { path: 'logout', element: <Logout/> },
            
            { path: 'register', element: <Register/> },            
            { path: 'forget-password', element: <ForgetPassword/> },            
          ],
        },
        {
          path: 'joingameroom/:code',          
          element: <JoinGameRoom />,
        },
        {
          path: 'pricing',
          element: <Pricing />,
        },
        {
          path: 'payment-success',
          element: <PaymentSuccess />,
        },
        {
          path: 'payment-failure',
          element: <PaymentFailure />,
        }
      ],
    },
    {
      // auth protected routes
      path: '/',
      element: <PrivateRoute roles={'Admin'} component={Layout} />,
      children: [
        {
          path: 'gamerooms/new',          
          element: <PrivateRoute roles={'Admin'} component={NewGameRoom} />,
        },
        {        
          path: 'gamerooms',        
          element: <PrivateRoute roles={'Admin'} component={GameRooms} />,         
        },     
        {        
          path: 'gameroom',        
          element: <PrivateRoute roles={'Admin'} component={GameRoom} />,         
        }, 
        {        
          path: 'challenge/:id',        
          element: <PrivateRoute roles={'Admin'} component={Challenge} />,         
        },                
        
        {
          path: 'chat',          
          element: <PrivateRoute roles={'Admin'} component={Chat} />,
        },
        {
          path: 'subscriptions',          
          element: <PrivateRoute roles={'Admin'} component={Subscriptions} />,
        },
        {
          path: 'students',          
          element: <PrivateRoute roles={'Admin'} component={Students} />,
        },
        
          
        
      ],
    },
  ]);
};

export { AllRoutes };
